(function () {
  'use strict';

  class ClassroomsRest {
    constructor($resource, env) {
      this.$resource = $resource;
      this.env = env;
    }

    classrooms() {
      return this.$resource(`${this.env.api}/classrooms`);
    }

    classroom() {
      return this.$resource(`${this.env.api}/classrooms/:id/:metrics`, {id: '@classroom.id', metrics: '@metrics'}, {update: {method: 'PUT'}});
    }

    results() {
      return this.$resource(`${this.env.api}/classrooms/:id/module_sessions/:module_session_id/results`, {id: '@id', module_session_id: '@module_session_id'}, {update: {method: 'PUT'}});
    }

    module_sessions() {
      return this.$resource(`${this.env.api}/classrooms/:id/module_sessions`, {id: '@module_session.id'}, {update: {method: 'PUT'}});
    }

    module_session() {
      return this.$resource(`${this.env.api}/classrooms/:id/module_sessions/:session_id`, {id: '@module_session.id', session_id: '@module_session.session_id'});
    }

    module_sessions_complete() {
      return this.$resource(`${this.env.api}/classrooms/:id/module_sessions/:session_id/complete`, {id: '@module_session.id', session_id: '@module_session.session_id'}, {update: {method: 'PUT'}});
    }

    lomMetadata() {
      return this.$resource(`${this.env.api}/classrooms/:id/lom-metadata`, {id: '@id'});
    }

    myClassrooms() {
      return this.$resource(`${this.env.api}/classrooms/my-classrooms`);
    }

    export() {
      return this.$resource(`${this.env.api}/classrooms/:id/export`, {id: '@id'});
    }
  }

  /**
   * @ngdoc service
   * @name components.service:ClassroomsRest
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('ClassroomsRest', ClassroomsRest);
}());
